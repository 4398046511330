var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-pane active"},[_c('div',{staticClass:"responsive-table"},[(_vm.loading)?_c('div',{staticClass:"loading min-h-300"},[_c('LoadingAnim')],1):(_vm.campaigns.length > 0)?_c('table',{staticClass:"table"},[_c('thead',{},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$tc('app.name', 1)))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('app.created_at')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('app.sent_at')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$tc('app.message', 2)))])])]),_c('tbody',_vm._l((_vm.campaigns),function(campaign){return _c('tr',{key:campaign.id},[_c('td',{attrs:{"data-label":"Data"}},[_c('div',[_vm._v(" "+_vm._s(campaign.name ? campaign.name : campaign.interface)+" ")])]),_c('td',{attrs:{"data-label":"Tipo"}},[_vm._v(" "+_vm._s(_vm._f("formatDate24")(campaign.created_at))+" ")]),_c('td',{attrs:{"data-label":"Remetente"}},[_vm._v(_vm._s(_vm._f("formatDate24")(campaign.scheduled_at)))]),_c('td',{attrs:{"data-label":"Status"}},[(campaign.status == 'accepted')?_c('span',{staticClass:"badge badge-light"},[_vm._v(_vm._s(_vm.$tc('generic-str.status.lbl-pending', 1)))]):(campaign.status == 'queued')?_c('span',{staticClass:"badge badge-secondary"},[_vm._v(_vm._s(_vm.$t('generic-str.status.lbl-queue')))]):(campaign.status == 'sending')?_c('span',{staticClass:"badge badge-accent"},[_vm._v(_vm._s(_vm.$t('generic-str.status.lbl-sending')))]):(campaign.status == 'sent')?_c('span',{staticClass:"badge badge-info"},[_vm._v(_vm._s(_vm.$t('generic-str.status.lbl-sent')))]):(campaign.status == 'failed')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                `${_vm.$t('sms.infos.filters.status.lbl-error')} ${
                  campaign.error_code
                }: ${campaign.error_message}`
              ),expression:"\n                `${$t('sms.infos.filters.status.lbl-error')} ${\n                  campaign.error_code\n                }: ${campaign.error_message}`\n              ",modifiers:{"top":true}}],staticClass:"badge badge-danger"},[_vm._v(_vm._s(_vm.$tc('app.failed')))]):(campaign.status == 'delivered')?_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.$tc('app.delivered')))]):(campaign.status == 'undelivered')?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                _vm.$t('generic-str.status.lbl-error') +
                ` ${campaign.error_code}: ${campaign.error_message}`
              ),expression:"\n                $t('generic-str.status.lbl-error') +\n                ` ${campaign.error_code}: ${campaign.error_message}`\n              ",modifiers:{"top":true}}],staticClass:"badge badge-dark"},[_vm._v(_vm._s(_vm.$t('generic-str.status.lbl-unavailable')))]):(campaign.status == 'received')?_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.$tc('generic-str.status.lbl-response', 1)))]):_vm._e()]),_c('td',{staticClass:"max-200",attrs:{"data-label":"Mensagens"}},[_c('router-link',{staticClass:"text-primary link-out-side",attrs:{"to":`/whatsapp/jv/reports/history/${campaign.id}`}},[_c('span',[_vm._v("Ver Mensagens")]),_vm._v(" "),_c('span',{staticClass:"material-symbols-outlined link-message"},[_vm._v("arrow_outward")])])],1)])}),0)]):_vm._e(),_c('div',{staticClass:"row"},[_c('pagination',{attrs:{"lastPage":_vm.pages},on:{"change":_vm.fetch}})],1),(_vm.campaigns.length == 0 && !_vm.loading)?_c('div',{staticClass:"no-item-list"},[_c('span',{staticClass:"material-symbols-outlined"},[_vm._v(" campaign ")]),_c('span',[_vm._v("Nenhuma campanha enviada")])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }